#project-scroll {

    height: 40vh;
    width: 40vw;
    min-height: 40vh;
    min-width: 300px;
    max-height: 40vh;
    max-width: 40vw;

}

.carousel {
    border: rgba(255, 255, 255, 0.5) 13px solid;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(7px);
    box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.5);
    color: white;
}

.carousel-item {

    background-color: rgba(255, 255, 255, 0.2);

}
      
.slide-image {

    margin: auto; 
    padding: auto;
    height: 40vh;
    width: 40vw;

}

