#main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    height: 100vh;
    width: 100vw;
    min-width: 100vw;
    min-height: 100vh;
    margin: 0px;
    z-index: 3;

}

#main-reverser {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    position: absolute;
    height: 100vh;
    width: 100vw;
    min-width: 100vw;
    min-height: 100vh;
    margin: 0px;
    z-index: 3;
}

#centre-row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap-reverse;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

#splash-main {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: absolute;
    z-index: 4;
    margin-top: 10vh;
    width: 90vw;
    min-width: 90vw;

    height: 70vh;
    min-height: 70vh;

}

.splash-item {
    display: flex;
    flex-direction: row;
    padding: 1vh;
    justify-content: center;
    align-items: center;
    height: 3vh;
}
