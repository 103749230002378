#background-full{
    display: flex;
    position: absolute;
    height: 100vh;
    width: 100vw;
    min-width: 100vw;
    min-height: 100vh;
    margin: 0px;
    z-index: 1;
    background-color: #696969;
}
.backgrounds {
    width: 25%;

}

/* #bg-container1{

} */