.info-panel {

    display: flex;
    flex-direction: column;
    /* height: 60vh; */
    min-height: 300px;
    width: 23vw;
    min-width: 200px;
    padding: 20px;
    margin: 2vh;


}

.info-header {

    font-size: large;
    font-weight: bold;
    text-align: left;
    margin: 2vh;
}

.info-body {

    margin: 2vh;
    text-align: left;
}

.info-link-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: 5vh;
}