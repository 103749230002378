.App {
  text-align: center;
    display: flex;
    position: absolute;
    height: 100vh;
    width: 100vw;
    min-width: 100vw;
    min-height: 100vh;
    margin: 0px;
    font-family: 'Fira Sans', sans-serif;
}

