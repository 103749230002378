.frost-box {

    
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(7px);
    box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.5);
    color: white;


}

.frostbox-announce-head{

    font-size: 50px;
}

.frostbox-button{
    background-color: rgba(255, 255, 255, 0);
    border: solid white 2px;
    margin: 3vh;

    padding: 20px;
    width: fit-content;
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: large;
    
}

.frost-link {

    font-weight: 500;
    color: white;
    border: solid 1px white;
    padding: 5px;

}

.frost-link:hover {

    color: white;
    padding: 7px;
    text-decoration: none;
    font-weight: 600;
    
}