#navbar{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    position: relative;
    width: 60vw;
    min-width: 60vw;

    height: 7vh;
    min-height: 7vh;

    margin: 4vh;
        

}

.navbar-logo {

    font-size: xx-large;
    max-height: 32px;
    width: auto;
    filter: saturate(100%);
}

.navbar-logo:hover {
    margin-bottom: 7px;
}

.navbar-link {

    color: white;
    font-weight: 600;
}

.navbar-link:hover {

    color:white;
    text-decoration: none;
    margin-bottom: 7px;
}
