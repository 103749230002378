.project-choicebox {

    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    /* height: 20vh;
    width: 25vh; */
    margin: 2vw;
}

.project-choicebox:hover{

    color:white;
    text-decoration: none;
}

.project-choice-image {

    width: 17vh;
    height: auto;
    margin: 0vh 3vh 0vh 3vh;
}

.project-choice-title {

    margin: 1vh;
}



.project-choice-type {

    font-size: small;
    margin: 1vh;
}

.project-main-image {

    width: 40vw;
    height: auto;
}

.project-image-container{

    padding: 3vh;
}

.comment-panel {
    padding: 1vh;
    margin: 5vh;
    max-width: 80vw;
}

.techs-panel {

    color: green;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(7px);
    box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.5);
}

.scroll-container {

    
    /* position: absolute; */
    display: flex;
    z-index: 3;
    overflow-y: auto;
}


